import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"
import "slim-select/styles"
import { post } from "@rails/request.js"

// Connects to data-controller="user-selector"
export default class extends Controller {
  connect() {
    new SlimSelect({
      select: this.element,
      settings: {
        placeholderText: "Модератор",
      },
      events: {
        afterChange: (newVal) => {
          const existUsers = Array.from(
            document.querySelectorAll("#seances_staff_users__user_id")
          ).map((el) => el.value)
          const inputUserIds = newVal.map((el) => el.value)
          let userId
          let act

          if (inputUserIds.length > existUsers.length) {
            userId = inputUserIds.filter((el) => !existUsers.includes(el))[0]
            act = "append"
          } else {
            userId = existUsers.filter((el) => !inputUserIds.includes(el))[0]
            act = "remove"
          }

          post("/admin/seances_staff/form_control", {
            responseKind: "turbo-stream",
            query: { user_id: userId, act },
          })
        },
      },
    })
  }
}
